<template>
    <div>
      <vs-card v-if="toggleScreenSize">
        <div class="justify-between flex flex-no-wrap">
          <h3 class="mt-3 mb-10 pl-2">Drugbook</h3>
        </div>
        <div class="flex justify-between items-center mb-4">
          <div
            class="flex w-full md:flex-no-wrap flex-wrap gap-3"
            v-if="filterCheck()"
          >
            <div
              class="flex justify-between items-center w-full md:w-3/5 pt-2 pr-2 filterContainer customBorder"
            >
              <vs-select
                ref="orgSelect"
                class="customSelect m-0"
                width="100%"
                label="Organization"
                name="organization"
                v-model="organizationId"
                autocomplete
                icon=""
              >
                <vs-select-item
                  :key="index"
                  :value="item._id"
                  :text="item.name"
                  v-for="(item, index) in organizationList"
                />
              </vs-select>
              <div class="cursor-pointer changeBtn" @click="changeOrg">
                Change
              </div>
            </div>
            <div
              class="flex justify-between items-center w-full md:w-3/5 pt-2 pr-2 filterContainer customBorder"
            >
              <vs-select
                ref="yearSelect"
                class="customSelect m-0"
                width="100%"
                label="Year"
                name="year"
                v-model="selectedYear"
                autocomplete
                icon=""
                :disabled="filterDisabled.year"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in customYears"
                />
              </vs-select>
              <div class="cursor-pointer changeBtn" @click="changeYear">
                Change
              </div>
            </div>
            <div
              class="flex justify-between items-center w-full md:w-3/5 pt-2 pr-2 filterContainer customBorder"
            >
              <vs-select
                ref="monthSelect"
                class="customSelect m-0"
                width="100%"
                label="Month"
                name="month"
                v-model="selectedMonth"
                autocomplete
                icon=""
                :disabled="filterDisabled.month"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in customMonths"
                />
              </vs-select>
              <div class="cursor-pointer changeBtn" @click="changeMonth">
                Change
              </div>
            </div>
            <div
              class="flex justify-between items-center w-full md:w-3/5 pt-2 pr-2 filterContainer customBorder"
            >
              <vs-select
                ref="clinicSelect"
                class="customSelect"
                width="100%"
                label="Clinic"
                name="clinic"
                v-model="selectedClinic"
                autocomplete
                icon=""
                :disabled="filterDisabled.clinic"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.name"
                  v-for="(item, index) in clinicsList"
                />
              </vs-select>
              <div class="cursor-pointer changeBtn" @click="changeClinic">
                Change
              </div>
            </div>
            <div
              class="flex justify-between items-center w-full md:w-3/5 pt-2 pr-2 filterContainer customBorder"
            >
              <vs-select
                ref="drugSelect"
                class="customSelect"
                width="100%"
                label="Drug"
                name="drug"
                v-model="selectedDrug"
                autocomplete
                icon=""
                :disabled="filterDisabled.drug"
              >
                <div :key="index" v-for="(item, index) in filteredProducts">
                  <vs-select-group :title="item.title" v-if="item.group">
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item, index) in item.group"
                    />
                  </vs-select-group>
                </div>
              </vs-select>
              <div class="cursor-pointer changeBtn" @click="changeDrug">
                Change
              </div>
            </div>
          </div>
          <div
            class="flex w-full md:flex-no-wrap flex-wrap gap-3"
            v-else
          >
            <div class="flex justify-between items-center w-full md:w-3/5 p-2">
              <vs-select
                width="100%"
                placeholder="Select Organization"
                name="organization"
                v-model="organizationId"
                autocomplete
              >
                <vs-select-item
                  :key="index"
                  :value="item._id"
                  :text="item.name"
                  v-for="(item, index) in organizationList"
                />
              </vs-select>
            </div>
            <div class="flex justify-between items-center w-full md:w-3/5 p-2">
              <vs-select
                width="100%"
                placeholder="Select Year"
                name="year"
                v-model="selectedYear"
                autocomplete
                :disabled="filterDisabled.organization"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in customYears"
                />
              </vs-select>
            </div>
            <div class="flex justify-between items-center w-full md:w-3/5 p-2">
              <vs-select
                width="100%"
                placeholder="Select Month"
                name="month"
                v-model="selectedMonth"
                autocomplete
                :disabled="filterDisabled.organization"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in customMonths"
                />
              </vs-select>
            </div>
            <div class="flex justify-between items-center w-full md:w-3/5 p-2">
              <vs-select
                v-if="clinicsList"
                width="100%"
                placeholder="Select Clinic"
                name="clinic"
                v-model="selectedClinic"
                autocomplete
                :disabled="filterDisabled.organization"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.name"
                  v-for="(item, index) in clinicsList"
                />
              </vs-select>
            </div>
            <div class="flex justify-between items-center w-full md:w-3/5 p-2">
              <vs-select
                width="100%"
                placeholder="Select Drug"
                name="drug"
                v-model="selectedDrug"
                autocomplete
                :disabled="filterDisabled.organization"
              >
                <div :key="index" v-for="(item, index) in filteredProducts">
                  <vs-select-group :title="item.title" v-if="item.group">
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item, index) in item.group"
                    />
                  </vs-select-group>
                </div>
              </vs-select>
            </div>
          </div>
        </div>
        <template v-if="filterCheck()">
          <div class="w-full flex my-5 gap-3 md:flex-no-wrap flex-wrap">
            <div class="flex w-full md:w-1/2 gap-3">
              <div class="w-1/2">
                <div class="w-full flex items-center py-5 totalContainer">
                  <div class="w-full text-center">
                    <span>Total Received</span>
                    <h4>
                      {{
                        `${summary.received.toFixed(1)} ${
                          summary.dosage === "unit" ? "u" : summary.dosage
                        }`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full flex items-center py-5 totalContainer">
                  <div class="w-full text-center">
                    <span>Total Administered</span>
                    <h4>
                      {{
                        `${summary.administered.toFixed(1)} ${
                          summary.dosage === "unit" ? "u" : summary.dosage
                        }`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full md:w-1/2 gap-3">
              <div class="w-1/2">
                <div class="w-full flex items-center py-5 totalContainer">
                  <div class="w-full text-center">
                    <span>Total Discarded</span>
                    <h4>
                      {{
                        `${summary.discarded.toFixed(1)} ${
                          summary.dosage === "unit" ? "u" : summary.dosage
                        }`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full flex items-center py-5 totalContainer">
                  <div class="w-full text-center">
                    <span>Total Current Balance</span>
                    <h4>
                      {{
                        `${summary.current.toFixed(1)} ${
                          summary.dosage === "unit" ? "u" : summary.dosage
                        }`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="data-list-list-view"
            class="data-list-container"
            v-if="filterCheck()"
          >
            <vs-table
              class="hidden md:block"
              ref="table"
              :sst="true"
              :total="totalDocs"
              :data="drugEntries"
              :max-items="dataTableParams.limit"
              :noDataText="noDataText"
              @change-page="handleChangePage"
            >
              <template slot="thead">
                <vs-th sort-key="date">Date</vs-th>
                <vs-th sort-key="time">Time</vs-th>
                <vs-th sort-key="clinic">Clinic</vs-th>
                <vs-th sort-key="drug">Drug</vs-th>
                <vs-th sort-key="area">Area</vs-th>
                <vs-th sort-key="patient">Patient</vs-th>
                <vs-th sort-key="amountRecieved">Recieved</vs-th>
                <vs-th sort-key="amountGiven">Administered</vs-th>
                <vs-th sort-key="discard">Discarded</vs-th>
                <vs-th sort-key="balance">Balance</vs-th>
                <vs-th sort-key="batchNumber">Batch Number</vs-th>
                <vs-th sort-key="batchNumber"
                  >Received, Administered, Discarded By</vs-th
                >
                <vs-th sort-key="batchNumber">Prescriber</vs-th>
                <vs-th sort-key="discardReason">Notes</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td
                    :data="tr.entryDate"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ moment(tr.entryDate).format("DD/MM/YYYY") }}</vs-td
                  >
                  <vs-td
                    :data="tr.entryDate"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ moment(tr.entryDate).format("hh:mm A") }}</vs-td
                  >
                  <vs-td
                    :data="tr.clinicId"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ clinicData?clinicData.name:'' }}</vs-td
                  >
                  <vs-td
                    :data="tr.productInfo"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ tr.productInfo.name ? tr.productInfo.name : tr.customProduct.name ? tr.customProduct.name : '' }}</vs-td
                  >
                  <vs-td
                    :data="tr.area"
                    :class="tr.overriden && ' line-through opacity-50'"
                  >
                    {{ tr.area }}</vs-td
                  >
                  <vs-td
                    :data="tr.patientName"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ tr.patientName | capitalize }}</vs-td
                  >
                  <vs-td
                    :data="tr.amount"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ getAmount("stocked", tr) }}</vs-td
                  >
                  <vs-td
                    :data="tr.amount"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ getAmount("used", tr) }}</vs-td
                  >
                  <vs-td
                    :data="tr.amount"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ getAmount("discarded", tr) }}</vs-td
                  >
                  <vs-td
                    :data="tr.balance"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ parseFloat(tr.balance).toFixed(1) }}</vs-td
                  >
                  <vs-td
                    :data="tr.batchNumber"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ tr.batchNumber }}</vs-td
                  >
                  <vs-td
                    :data="tr.performedBy? tr.performedBy.name :''"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ tr.performedBy? tr.performedBy.name :'' }}</vs-td
                  >
                  <vs-td
                    :data="tr.prescriber? tr.prescriber.name :''"
                    :class="tr.overriden && ' line-through opacity-50'"
                    >{{ tr.prescriber.name ? tr.prescriber.name : tr.customPrescriber.name ? tr.customPrescriber.name : 'Fresh Clinics' }}</vs-td
                  >
                  <vs-td
                    :data="tr.discardReason"
                    :class="tr.overriden && ' line-through opacity-50'"
                  >
                    {{ tr.discardReason }}</vs-td
                  >
                </vs-tr>
              </template>
            </vs-table>
            <template v-if="toggleScreenSize && isMobileView">
              <div v-if="isMobileView">
                <vs-collapse type="default">
                  <vs-collapse-item
                    v-for="item in drugEntries"
                    :key="item._id"
                    :not-arrow="true"
                    ref="vsCollapseItem"
                  >
                    <div slot="header">
                      <div class="flex justify-between">
                        <div class="">
                          <div>
                            <span
                              :class="`basic-text ${
                                item.overriden ? 'line-through' : null
                              }`"
                              >{{ item.productInfo ? item.productInfo.name : item.customProduct.name ? tr.customProduct.name:'' }}</span
                            >
                          </div>
                          <div>
                            <span
                              :class="`basic-text ${
                                item.overriden ? 'line-through' : null
                              }`"
                            >
                              {{ moment(item.entryDate).format("hh:mm A") }}
                            </span>
                          </div>
                        </div>
                        <div class="">
                          <vs-chip
                            v-if="item.type === 'discarded'"
                            transparent
                            :class="`basic-text ${
                              item.overriden ? 'line-through' : null
                            }`"
                            color="rgba(253, 198, 198, 1)"
                          >
                            {{
                              `-${parseFloat(item.amount).toFixed(1)} ${
                                item.productInfo.dosage
                              }`
                            }}
                          </vs-chip>
                          <vs-chip
                            v-if="item.type === 'stocked'"
                            transparent
                            :class="`basic-text ${
                              item.overriden ? 'line-through' : null
                            }`"
                            color="rgba(132, 205, 184, 0.2)"
                          >
                            {{
                              `+${parseFloat(item.amount).toFixed(1)} ${
                                item.productInfo.dosage
                              }`
                            }}
                          </vs-chip>
                          <span v-if="item.type === 'used'">
                            {{
                              `-${parseFloat(item.amount).toFixed(1)} ${
                                item.productInfo.dosage
                              }`
                            }}
                          </span>
                          <div>
                            <span
                              :class="`basic-text ${
                                item.overriden ? 'line-through' : null
                              }`"
                            >
                              Balance: {{ parseFloat(item.balance).toFixed(1) }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div v-if="!item.overriden">
                        <vs-chip
                          v-if="item.type === 'discarded'"
                          class="basic-text"
                          transparent
                          color="rgba(253, 198, 198, 1)"
                        >
                          Discarded
                        </vs-chip>
                        <vs-chip v-if="item.type === 'stocked'">
                          Recieved
                        </vs-chip>
                        <vs-chip v-if="item.type === 'used'">
                          Administered
                        </vs-chip>
                      </div>
                      <vs-chip v-if="item.overriden"> Written in error </vs-chip>
                      <br /><br />
                      <div
                        v-if="item.type === 'stocked'"
                        class="w-full text-gray-500 text-sm py-1"
                      >
                        Received by
                      </div>
                      <div
                        v-if="item.type === 'discarded'"
                        class="w-full text-gray-500 text-sm py-1"
                      >
                        Discarded by
                      </div>
                      <div
                        v-if="item.type === 'used'"
                        class="w-full text-gray-500 text-sm py-1"
                      >
                        Administered by
                      </div>
                      <div
                        :class="`w-full py-1 basic-text ${
                          item.overriden ? 'line-through' : null
                        }`"
                      >
                        {{ item.performedBy?item.performedBy.name:"" }}
                      </div>

                      <div class="w-full text-gray-500 text-sm py-1">Clinic</div>
                      <div
                        :class="`w-full py-1 basic-text ${
                          item.overriden ? 'line-through' : null
                        }`"
                      >
                        {{ clinicData?clinicData.name:'' }}
                      </div>

                      <div
                        v-if="item.type === 'used'"
                        class="w-full text-gray-500 text-sm py-1"
                      >
                        Patient
                      </div>
                      <div
                        v-if="item.type === 'used'"
                        :class="`w-full py-1 basic-text ${
                          item.overriden ? 'line-through' : null
                        }`"
                      >
                        {{ item.patientName || "--" | capitalize }}
                      </div>

                      <div
                        v-if="item.type === 'used'"
                        class="w-full text-gray-500 text-sm py-1"
                      >
                        Area
                      </div>
                      <div
                        v-if="item.type === 'used'"
                        :class="`w-full py-1 basic-text ${
                          item.overriden ? 'line-through' : null
                        }`"
                      >
                        {{ item.treatmentArea || "--" }}
                      </div>

                      <div class="w-full text-gray-500 text-sm py-1">
                        Batch number
                      </div>
                      <div
                        :class="`w-full py-1 basic-text ${
                          item.overriden ? 'line-through' : null
                        }`"
                      >
                        {{ item.batchNumber || "--" }}
                      </div>

                      <div class="w-full text-gray-500 text-sm py-1">
                        Batch Expiry
                      </div>
                      <div
                        :class="`w-full py-1 basic-text ${
                          item.overriden ? 'line-through' : null
                        }`"
                      >
                        {{ moment(item.expiryDate).format("DD/MM/YYYY") || "--" }}
                      </div>

                      <div class="w-full text-gray-500 text-sm py-1">
                        Prescribed By
                      </div>
                      <div
                        :class="`w-full py-1 basic-text ${
                          item.overriden ? 'line-through' : null
                        }`"
                      >
                        {{ item.prescriber.name ? item.prescriber.name :  item.customPrescriber.name ? item.customPrescriber.name : 'Fresh Clinics' }}
                      </div>

                      <div
                        v-if="item.type === 'discarded'"
                        class="w-full text-gray-500 text-sm"
                      >
                        Notes
                      </div>
                      <div
                        v-if="item.type === 'discarded'"
                        :class="`w-full py-1 basic-text ${
                          item.overriden ? 'line-through' : null
                        }`"
                      >
                        {{ item.discardReason || "--" }}
                      </div>
                    </div>
                  </vs-collapse-item>
                </vs-collapse>
              </div>
            </template>
            <div class="p-3 flex justify-end">
              <div class="m-2">
                <pagination
                  :hasPrev="hasPrev"
                  :hasNext="hasNext"
                  @handleOnChangePage="handleChangePage"
                />
                <!-- <span class="mr-2">
                  {{
                    dataTableParams.page * dataTableParams.limit -
                    (dataTableParams.limit - 1)
                  }}
                  -
                  {{
                    totalDocs - dataTableParams.page * dataTableParams.limit > 0
                      ? dataTableParams.page * dataTableParams.limit
                      : totalDocs
                  }}
                  of {{ totalDocs }}
                </span> -->
                <!-- <div class="pagination-div hidden lg:block">
                  <paginate
                    :page-count="totalPage"
                    :click-handler="handleChangePage"
                    class="pagination"
                    :page-range="9"
                    :prevText="'<'"
                    :nextText="'>'"
                  ></paginate>
                </div> -->
                <!-- <div class="pagination-div lg:hidden">
                  <paginate
                    :page-count="totalPage"
                    :click-handler="handleChangePage"
                    class="pagination"
                    :page-range="9"
                    :prevText="'<'"
                    :nextText="'>'"
                  ></paginate>
                </div> -->
              </div>
            </div>
          </div>
        </template>
        <div class="no-filter-view" v-else>
          <p>
            To view your drugbook, please select the desired
            <strong>Organization, Month, Year, Drug, and Clinic</strong>
          </p>
        </div>
      </vs-card>
    </div>
  </template>

  <script>
  import Vue from "vue";
  import _ from "lodash";
  import Datepicker from "vuejs-datepicker";
  import { Validator } from "vee-validate";
  import { mapActions } from "vuex";
  import moment from "moment";
  import Pagination from "../../general-component/pagination.vue";

  const dict = {
    custom: {
      dateAdded: {
        required: "Please enter the date added",
      },
    },
  };

  Validator.localize("en", dict);
  Vue.prototype.moment = moment;
  export default {
    components: {
      Datepicker,
      Pagination,
    },
    data: () => ({
      prevRoute: "",
      noDataText: "No Data Available",
      totalDocs: 0,
      summary: {
        administered: 0,
        current: 0,
        discarded: 0,
        dosage: "unit",
        received: 0,
      },
      currentPage: 1,
      userId: "",
      searchInputString: "",
      organizationId: "",
      selectedRecord: {},
      drugItems: [],
      customProducts: [],
      clinicsList: [],
      filteredProducts: [],
      organizationList: [],
      customYears: [],
      customMonths: [],
      months:[
        { text: "January", value: "01" },
        { text: "February", value: "02" },
        { text: "March", value: "03" },
        { text: "April", value: "04" },
        { text: "May", value: "05" },
        { text: "June", value: "06" },
        { text: "July", value: "07" },
        { text: "August", value: "08" },
        { text: "September", value: "09" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      firstEntryYear: null,
      discardRecordPopup: false,
      selectedOrg: "",
      selectedMonth: "",
      selectedYear: "",
      selectedClinic: "",
      selectedDrug: "",
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 100,
        selectedClinic: "",
        productId: "",
        month: "",
        year: "",
      },
      drugEntries: [],
      filterDisabled: {
        organization: true,
        month: true,
        drug: true,
        clinic: true,
        year: true,
      },
      clinicData: null,
      drugData: null,
      isMobileView: null,
      hasPrev: false,
      hasNext: false,
    }),
    methods: {
      ...mapActions("drugbook", [
        "fetchDrugEntries",
        "getFirstEntryDate",
        "getAmountPerClinic",
        "fetchCustomProducts",
      ]),
      ...mapActions("organization", [
        "fetchOrganizationAllClinics",
        "fetchOrganizationAssignList",
        "fetchOrganizationWithDrugEntries"
      ]),
      ...mapActions("product", ["fetchProducts"]),
      getOrganizationList(id){
        this.$vs.loading();
        this.fetchOrganizationWithDrugEntries().then((res) => {
            this.organizationList = res.data.data;
            this.$vs.loading.close();
        }).catch((err) => {
            this.$vs.loading.close();
            console.error(err);
        })
      },
      async fetchFirstEntryDate(clinicID) {
        try {
          this.$vs.loading();
          const res = await this.getFirstEntryDate({ clinicId: clinicID });
          if (res.data.data) {
            if (res.data.data.year == parseInt(this.selectedYear)){
              this.customMonths =[...this.months].filter(
              (e) =>
                  Math.round(parseInt(e.value)) >= res.data.data.month &&
                  (moment(new Date()).format("Y")> this.selectedYear || parseInt(e.value) <= parseInt(moment(new Date()).format("M")))
              );
            }
            else if (moment(new Date()).format("Y") > this.selectedYear ){
              this.customMonths =[...this.months]
            }
            else if (moment(new Date()).format("Y") == this.selectedYear ){
              this.customMonths =[...this.months].filter(
              (e) =>
                  parseInt(e.value) <= parseInt(moment(new Date()).format("M"))
              );
            }

            if (!this.customMonths.length) {
              this.customMonths[0] = { text: "January", value: "01" }
            }
             if ( !_.find(this.customMonths,['value',this.selectedMonth])) {
              if (parseInt(this.selectedYear) !==  parseInt(moment().format('YYYY')) ) {
                this.selectedMonth = this.customMonths[0].value;
              }
              else{
                this.selectedMonth = this.customMonths[this.customMonths.findIndex((element) => element.value === moment().format('MM'))].value;
              }
            }

            this.firstEntryYear = res.data.data.year;
            this.customYearsGetter(res.data.data.year);
          }
          this.$vs.loading.close();
        } catch (err) {
          console.error(err);
        }
      },
      async fetchAmountPerClinic(clinicID) {
        try {
          const res = await this.getAmountPerClinic({ clinicId: clinicID });
          const clinicsWithAmount = res.data.data
            .map((e) => {
              const id = e._id.product || e._id.custom
              return {
                text:  [...this.drugItems, ...this.customProducts].find((item) => item._id === id).name,
                value: id,
              };
            })
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            );
          if (clinicsWithAmount && [...this.drugItems, ...this.customProducts].length > 0) {
            let temp = [...this.drugItems, ...this.customProducts]
              .filter((e) => {
                const fromOrg = !e.organizationId || e.organizationId === this.organizationId;
                return !clinicsWithAmount.some((item) => item.value === e._id) && fromOrg && e.isAvailable;
              })
              .map((e) => {
                return {
                  text: e.name,
                  custom: e.organizationId,
                  value: e._id,
                };
              });
            let withBalance = [...clinicsWithAmount].sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1);
            this.filteredProducts = [
              {
                title: "With Balance",
                group: withBalance,
              },
              {
                title: "Without Balance",
                group: [...temp],
              },
            ];
            const isProductInWithBalance = withBalance.some((e) => `${e.value}` === `${this.selectedDrug}`);
            const isProductInWihtoutBalance = [...temp].some((e) => `${e.value}` === `${this.selectedDrug}`);
            const isProductInList = isProductInWithBalance || isProductInWihtoutBalance;

            if (!isProductInList || this.selectedDrug.length === 0) {
              this.selectedDrug = this.filteredProducts[0].group[0].value;
            }
          }
        } catch (err) {
          console.error(err);
        }
      },
      async getProductsList() {
        try {
          const res = await this.fetchProducts({
            search: this.search,
            limit: 1000,
          });
          this.drugItems = res.data.data.docs.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
        } catch (error) {
          console.error(error.message);
        }
      },
      async getCustomProducts() {
      try {
        const res = await this.fetchCustomProducts(this.organizationId);
        this.customProducts = res.data.data
      } catch (error) {
        console.error("ERR: ", error);
      }
    },
      async getDrugEntries() {
        try {
          this.$vs.loading();
          this.dataTableParams["selectedClinic"] = this.selectedClinic;
          this.dataTableParams.productId = this.selectedDrug;
          this.dataTableParams.month = this.selectedMonth;
          this.dataTableParams.year = this.selectedYear;
          const res = await this.fetchDrugEntries(this.dataTableParams);
          this.drugEntries = res.data.data.docs;
          // this.totalDocs = res.data.data.pagination.total;
          this.summary = res.data.data.summary;
          this.hasPrev = res.data.data.hasPrev;
          this.hasNext = res.data.data.hasNext;
          this.fetchAmountPerClinic(this.selectedClinic, false);
          this.$vs.loading.close();
        } catch (e) {
          console.error(e);
        }
      },
      async fetchclinics(orgId) {
        try {
          const res = await this.fetchOrganizationAllClinics(orgId);
          this.clinicsList = res.data.data.filter((e) => e.drugEntries > 0);
          if(this.clinicsList.length > 0){
            this.fetchFirstEntryDate(this.clinicsList[0].clinicId);
            this.fetchAmountPerClinic(this.clinicsList[0].clinicId);
            this.filterDisabled.organization = false;
          } else {
            this.selectedClinic = "";
            this.selectedMonth = "";
            this.selectedYear = "";
            this.selectedDrug = "";
            this.filterDisabled.organization = true;
          }
          const firstNonNullPositiveEntry = _.find(this.clinicsList, obj => obj.drugEntries !== null && obj.drugEntries > 0);
          this.fetchFirstEntryDate(firstNonNullPositiveEntry.clinicId);
          this.fetchAmountPerClinic(firstNonNullPositiveEntry.clinicId);
          if (this.selectedClinic) {
            // Check if selected default first clinic has drugentries
            this.selectedClinic = firstNonNullPositiveEntry.clinicId;
          }else{
            const inParams = this.selectedClinic
            this.selectedClinic = '';
            // Check if selected default first clinic has drugentries
           setTimeout(() => {
            this.selectedClinic = inParams || this.clinicsList[0].clinicId;
           }, 100);
          }
        } catch (err) {
          console.error(err);
        }
      },
      changeOrg() {
        this.$refs.orgSelect.focus();
        this.filterDisabled.organization = false;
      },
      changeMonth() {
        this.$refs.monthSelect.focus();
        this.filterDisabled.month = false;
      },
      changeYear() {
        this.$refs.yearSelect.focus();
        this.filterDisabled.year = false;
      },
      changeDrug() {
        this.$refs.drugSelect.focus();
        this.filterDisabled.drug = false;
      },
      changeClinic() {
        this.$refs.clinicSelect.focus();
        this.filterDisabled.clinic = false;
      },
      filterCheck() {
        if (
          this.selectedMonth &&
          this.selectedYear &&
          this.selectedDrug &&
          this.selectedClinic
        ) {
          return true;
        }
        return false;
      },
      handleChangePage(page) {
        if (typeof page === 'string'){
          if (page === "next") {
            delete this.dataTableParams.prev;
            delete this.dataTableParams.id;

            this.dataTableParams.next = true;
            this.dataTableParams.id = this.drugEntries[this.drugEntries.length - 1]._id;
          }
          if (page === "prev") {
            delete this.dataTableParams.next;
            delete this.dataTableParams.id;

            this.dataTableParams.prev = true;
            this.dataTableParams.id = this.drugEntries[0]._id;
          }
          this.getDrugEntries();
        }
      },
      myEventHandler() {
        this.isMobileView = window.innerWidth < 768;
      },
      getAmount(type, item) {
        if (type === item.type) {
          return parseFloat(item.amount).toFixed(1);
        }
        return null;
      },
      customYearsGetter(year) {
        let self = this;
        let currentYear = new Date().getFullYear();
        let years = [];
        for (let i = year; i <= currentYear; ++i) {
          years.push({
            text: i.toString(),
            value: i,
          });
        }
        if (this.selectedYear.length === 0) {
          self.selectedYear = years.reverse()[0].value;
        }
        this.customYears = years.reverse();
      },
    },
    computed: {
      totalPage: function () {
        return this.totalDocs / this.dataTableParams.limit >
          parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      },
      toggleScreenSize: function () {
        if (this.isMobileView) {
          return !this.discardRecordPopup && !this.addRecordPopUp;
        }
        return true;
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevRoute = from.fullPath;
      });
    },
    watch: {
      organizationId: function (newVal, oldVal) {
        if(newVal !== oldVal){
          this.fetchclinics(newVal);
          this.getProductsList();
          this.getCustomProducts();
        }
      },
      selectedYear: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchFirstEntryDate(this.selectedClinic)
          this.$router.replace({
            query: {
              selectedMonth: this.selectedMonth,
              selectedYear: this.selectedYear,
              selectedDrug: this.selectedDrug,
              selectedClinic: this.selectedClinic,
            },
          });
        }
      },
      selectedMonth: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          let monthYear = newVal.split(" ");
          this.dataTableParams.month = monthYear[0];
          this.dataTableParams.year = monthYear[1];
          if (this.filterCheck()) {
            delete this.dataTableParams.prev;
            delete this.dataTableParams.next;
            delete this.dataTableParams.id;
            this.getDrugEntries();
          }
          this.$router.replace({
            query: {
              organizationId: this.organizationId,
              selectedMonth: this.selectedMonth,
              selectedYear: this.selectedYear,
              selectedDrug: this.selectedDrug,
              selectedClinic: this.selectedClinic,
            },
          });
          this.filterDisabled.month = true;
        }
      },
      selectedDrug: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.filterCheck()) {
            delete this.dataTableParams.prev;
            delete this.dataTableParams.next;
            delete this.dataTableParams.id;
            this.getDrugEntries();
          }
          this.$router.replace({
            query: {
              organizationId: this.organizationId,
              selectedMonth: this.selectedMonth,
              selectedYear: this.selectedYear,
              selectedDrug: this.selectedDrug,
              selectedClinic: this.selectedClinic,
            },
          });
          this.filterDisabled.drug = true;
          this.drugData = this.drugItems.filter((e) => e._id === newVal)[0];
        }
      },
      selectedClinic: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchAmountPerClinic(newVal);
          if (this.filterCheck()) {
            delete this.dataTableParams.prev;
            delete this.dataTableParams.next;
            delete this.dataTableParams.id;
            this.getDrugEntries();
          }
          this.$router.replace({
            query: {
              organizationId: this.organizationId,
              selectedMonth: this.selectedMonth,
              selectedYear: this.selectedYear,
              selectedDrug: this.selectedDrug,
              selectedClinic: this.selectedClinic,
            },
          });
          this.filterDisabled.clinic = true;
          this.clinicData = this.clinicsList.filter(
            (e) => e.clinicId === newVal
          )[0];
        }
      },
    },
    filters: {
      momentDate: function (date) {
        return moment(date).format("DD/MM/YYYY");
      },
      momentTime: function (date) {
        return moment(date).format("H:mm:ss");
      },
    },
    mounted() {
      window.addEventListener("resize", this.myEventHandler);
      this.myEventHandler();
      this.organizationId = this.$route.query.organizationId || "";
      this.selectedClinic = this.$route.query.selectedClinic || "";
      this.selectedMonth = this.$route.query.selectedMonth || "";
      this.selectedYear = this.$route.query.selectedYear || "";
      this.selectedDrug = this.$route.query.selectedDrug || "";
      if (this.organizationId){
        this.getCustomProducts();
        this.fetchclinics(this.organizationId);

      }
    },
    created() {
      this.userId = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      )._id;
      this.getOrganizationList(this.userId);
    },
    destroyed() {
      window.removeEventListener("resize", this.myEventHandler);
    },
  };
  </script>

  <style lang="scss">
  .circle {
    top: 10px;
    right: 10px;
  }
  .fieldContainer {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .dropdownMenu {
    width: 200px;
  }
  .con-vs-popup > .vs-popup {
    width: 450px;
    height: auto;
  }
  .vs-popup--content {
    margin: auto;
  }
  .filterContainer {
    border: 100px;
  }
  .customSelect .vs-select--input {
    border: none;
  }
  .customSelect .vs-select--label,
  .vs-select--label.input-select-label-primary--active {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.6);
  }
  .customSelect .vs-select--input:disabled {
    opacity: 100;
    pointer-events: none;
    background-color: transparent;
  }
  #explainer .vs-popup{
    width: 600px;
    border-radius: 0px;
  }
  #explainer .vs-popup--content{
    width: 600px;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  #explainer .vs-popup .vs-popup--header{
    display: none;
  }
  .popUpCustom .vs-popup--content{
    overflow: visible;
  }
  .customBorder {
    border: 1px solid rgba(246, 246, 246, 1);
    border-radius: 7px;
  }
  .totalContainer {
    border-radius: 7px;
    background-color: rgba(185, 185, 185, 0.13);
  }
  .basic-text {
    color: rgba(7, 66, 48, 1) !important;
    font-size: 14px !important;
  }
  .no-filter-view {
    background-color: rgba(185, 185, 185, 0.13);
    padding: 5em 15em;
    display: flex;
    justify-content: center;
    text-align: center;
    min-height: 50vh;
    border-radius: 7px;
    P {
      color: rgba(7, 66, 48, 0.58);
      font-size: 1.125rem;
    }
  }
  .changeBtn {
    color: rgba(var(--vs-primary), 1);
  }
  .vs-select-group h4 {
    font-size: 1rem;
    font-weight: 600;
  }
  </style>
